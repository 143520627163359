import { driverCheckInApi } from './driverCheckInApi'
import {
  CheckInCreateCall,
  CreateCheckInApi,
  CreateCheckinCheckout
} from './driverCheckInTypes'

async function create(data: CreateCheckInApi) {
  const checkin = await driverCheckInApi.create(data)
  return checkin
}

async function getCheckInActives(companyId: string) {
  const checkInsActives = await driverCheckInApi.getCheckInActives(companyId)
  return checkInsActives
}

async function getDriversCalled(companyId: string) {
  const checkInsActives = await driverCheckInApi.getDriversCalled(companyId)
  return checkInsActives
}

async function getCount(companyId: string) {
  const count = driverCheckInApi.getCount(companyId)
  return count
}

async function createLogCheckout(data: CreateCheckinCheckout) {
  const checkout = await driverCheckInApi.createLogCheckout(data)
  return checkout
}

async function createCall(data: CheckInCreateCall) {
  const call = await driverCheckInApi.createCall(data)
  return call
}

export const driverCheckInServices = {
  create,
  getCount,
  getCheckInActives,
  createLogCheckout,
  getDriversCalled,
  createCall
}
