export function useSpeechSynthesis() {
  function talk(phrase: string) {
    const synth = window.speechSynthesis
    const voices = synth.getVoices()

    if (voices.length !== 0) {
      const msg = new SpeechSynthesisUtterance()
      msg.voice = voices[0]
      msg.rate = 1
      msg.pitch = 1
      msg.text = phrase
      msg.lang = 'pt-BR'
      synth.speak(msg)
    }
  }

  return { talk }
}
