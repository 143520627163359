import React, { useState } from 'react'
import api from '../../services/api'
import { LicensePlate } from '../LicensePlate'
import { handleMaskQtyTransported } from '../../utils/mask-qty-transported'

import {
  Modal as ModalComponent,
  ModalWrapper,
  ModalHeading,
  ModalBox,
  ModalTitle,
  ModalProps
} from '../Modal'

import './styles.scss'
import { toast } from 'react-toastify'
import { useQueueUpdateCountAttendance } from '../../domain'

interface Vehicle {
  id: number
  expedition: string

  scheduling: {
    id: number
    qtyOrdered: number
    truck: {
      licensePlate: string
      implementType: string

      vehicleModel: {
        name: string
      }
    }
  }
}

interface CompleteVehicleChargingModalProps extends ModalProps {
  vehicle: Vehicle
  onCloseModal: () => void
  companyId: string | number | null
}

export function CompleteVehicleChargingModal({
  open,
  onCloseModal,
  vehicle,
  companyId
}: CompleteVehicleChargingModalProps) {
  const { updateCountAttendance } = useQueueUpdateCountAttendance()

  const [qtyTransported, setQtyTransported] = useState('')

  function updateQtyTransported(event: React.ChangeEvent<HTMLInputElement>) {
    const { qtyTransported } = handleMaskQtyTransported(event)
    setQtyTransported(qtyTransported)
  }

  const qtyTransportedIsZero = Number(qtyTransported.replace(/,/g, '')) === 0

  const [isConpletingChargingVehicle, setIsConpletingChargingVehicle] =
    useState(false)

  async function handleConfirmVehicleCharging() {
    if (!qtyTransported.trim()) {
      return
    }

    setIsConpletingChargingVehicle(true)

    const formatQtyTransported = qtyTransported.replace(/,/g, '.')

    try {
      await api
        .post('/scheduling/close', {
          id: vehicle.scheduling.id,
          status: true,
          qtyTransported: Number(formatQtyTransported),
          expedInitialDate: vehicle.expedition,
          expedDate: new Date()
        })
        .then(() => {
          updateCountAttendance(Number(companyId))

          window.location.reload()
        })
    } catch (error) {
      toast.error('Erro ao encerrar atendimento')
    }
  }

  return (
    <ModalComponent open={open} onOpenChange={onCloseModal}>
      <ModalWrapper>
        <ModalHeading className='complete-vehicle-charging-modal'>
          <ModalTitle>
            Concluir <span>Carregamento</span>
          </ModalTitle>
        </ModalHeading>

        <ModalBox>
          <div className='box-vehicle__container'>
            <div className='box-vehicle__content'>
              <LicensePlate
                fontSize='22px'
                licensePlate={vehicle?.scheduling?.truck?.licensePlate}
              />

              <div className='vehicle__info'>
                <span className='vehicle__name'>
                  {vehicle?.scheduling?.truck?.vehicleModel.name}
                </span>

                <div>
                  <span className='vehicle__implement-type'>
                    {vehicle?.scheduling?.truck?.implementType}
                  </span>
                  {'-'}
                  <span className='vehicle__net-capacity'>
                    {vehicle?.scheduling?.qtyOrdered}t
                  </span>
                </div>
              </div>
            </div>

            <div className='complete-vehicle-charging-modal__main'>
              <div className='capacity-vehicle'>
                <div>
                  <span>Cap. Liq. Veículo</span>
                  <span className='capacity'>
                    {vehicle?.scheduling?.qtyOrdered}t
                  </span>
                </div>
              </div>

              <div className='scale-weight'>
                <div>
                  <span>Peso Balança</span>

                  <input
                    type='text'
                    placeholder='00,000'
                    onChange={e => updateQtyTransported(e)}
                  />
                </div>
              </div>
            </div>

            <div className='footer__buttons-actions'>
              <button
                onClick={onCloseModal}
                className='footer__buttons-actions__close'
              >
                Cancelar
              </button>
              <button
                onClick={handleConfirmVehicleCharging}
                disabled={qtyTransportedIsZero || isConpletingChargingVehicle}
                className='footer__buttons-actions__submit'
              >
                {isConpletingChargingVehicle ? 'Concluindo...' : 'Concluir'}
              </button>
            </div>
          </div>
        </ModalBox>
      </ModalWrapper>
    </ModalComponent>
  )
}
