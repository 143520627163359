import { CheckInCreateCall, QueueByDay } from '../../../../domain'
import { QueueTableRow } from './components/ExpeditionTables/expeditionTableTypes'

export const MAX_QUEUES_PER_DAY = 4
export const THREEMINUTES_IN_MILISECONDS_UPDATE_EXPED = 180000 // 3 minutes

export type TabQueueOptions = 'queue' | 'attendance' | 'courtyard'
export type TableKey = 'queue' | 'courtyard'

export interface ExpedTableProps {
  tableKey: TableKey
  rows: QueueTableRow[]
  getQueueByDay: (dayIndex: number) => QueueByDay[]
  index: number

  days: string[]
  dates: string[]

  shippingCompanyId: number | null

  tolerance: string

  isExpeditionViewer: boolean
  isConciergeViewer: boolean

  handleSelectScheduling: (row: QueueByDay) => void

  getToleranceTime: (attendanceTime: any) => string
  getTime: (attendanceTime: any) => string
  getLimitTime: (attendanceTime: any) => string
  checkInEnabled: boolean

  createCheckInCall?: (data: CheckInCreateCall) => void
}

export type ExpedTabTableProps = Omit<ExpedTableProps, 'rows' | 'index'>
