import React from 'react'
import Timer, { CircularTimerProps } from '../../../components/Timer/Timer'

export function ToleranceTimer(props: CircularTimerProps) {
  return (
    <>
      <div className='tolerance-message'>
        <span className='bold'>Você está dentro do prazo de tolerância.</span>
        <span>
          Após o termino desse prazo, sua posição será cancelada
          automaticamente.
        </span>
      </div>

      <Timer {...props} />
    </>
  )
}
