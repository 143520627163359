import React from 'react'
import { LicensePlate } from '../LicensePlate'
import {
  Modal as ModalComponent,
  ModalHeading,
  ModalTitle,
  ModalWrapper,
  ModalBox,
  ModalProps
} from '../Modal'

import './styles.scss'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { useQueueUpdateCountAttendance } from '../../domain'

interface Vehicle {
  id: number

  scheduling: {
    truck: {
      licensePlate: string
      implementType: string
      netCapacity: number

      vehicleModel: {
        name: string
      }
    }
  }
}

interface BackVehicleToQueueModalProps extends ModalProps {
  vehicle: Vehicle

  className?: string
  onCloseModal: () => void
  companyId: string | number | null
}

export function BackVehicleToQueueModal({
  vehicle,
  open,
  onCloseModal,
  companyId
}: BackVehicleToQueueModalProps) {
  const { updateCountAttendance } = useQueueUpdateCountAttendance()

  async function handleBackVehicleToQueue(id: number) {
    try {
      await api
        .put(`/queue/start`, {
          id: id,
          status: false
        })
        .then(() => {
          updateCountAttendance(Number(companyId))
          window.location.reload()
        })
    } catch (error) {
      toast.error('Erro ao voltar  veículo para a fila')
    }
  }

  return (
    <ModalComponent open={open} onOpenChange={onCloseModal}>
      <ModalWrapper withButtonCloseModal>
        <ModalHeading>
          <ModalTitle>
            Deseja voltar o veículo <span>para a fila?</span>
          </ModalTitle>
        </ModalHeading>

        <ModalBox>
          <div className='box-vehicle__container'>
            <div className='box-vehicle__content'>
              <LicensePlate
                fontSize='22px'
                licensePlate={vehicle.scheduling?.truck?.licensePlate}
              />

              <div className='vehicle__info'>
                <span className='vehicle__name'>
                  {vehicle.scheduling?.truck?.vehicleModel.name}
                </span>

                <div>
                  <span className='vehicle__implement-type'>
                    {vehicle.scheduling?.truck?.implementType}
                  </span>
                  {'-'}
                  <span className='vehicle__net-capacity'>
                    {vehicle.scheduling?.truck?.netCapacity}t
                  </span>
                </div>
              </div>
            </div>

            <div className='footer__buttons-actions'>
              <button
                onClick={onCloseModal}
                className='footer__buttons-actions__close'
              >
                Não
              </button>
              <button
                onClick={() => handleBackVehicleToQueue(vehicle.id)}
                className='footer__buttons-actions__submit'
              >
                Sim
              </button>
            </div>
          </div>
        </ModalBox>
      </ModalWrapper>
    </ModalComponent>
  )
}
