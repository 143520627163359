import React from 'react'
import { CgArrowDownO, CgArrowUpO } from 'react-icons/cg'
import { Scheduling } from '../../../../../domain'

type Props = {
  scheduling: Scheduling | null
  showDetails: boolean
  onShowDetails(): void
}

export function OrderDetails({
  scheduling,
  showDetails,
  onShowDetails
}: Props) {
  return (
    <div className='show-details'>
      <div className='details-button' onClick={onShowDetails}>
        Detalhes do pedido
        {showDetails ? <CgArrowUpO /> : <CgArrowDownO />}
      </div>

      {showDetails && (
        <div>
          <div>Pedido: {scheduling?.order?.code}</div>
          <div>
            <span>Transportadora:</span>
            <div>{scheduling?.order?.shippingCompany.name}</div>
          </div>
          <div>
            <span>Destinatário:</span>
            {scheduling?.order?.customer.name}
          </div>
          <div>
            <span>Município:</span>
            {scheduling?.order?.customer.city}
          </div>
          <div>
            <span>Produto:</span>
            {
              scheduling?.order?.orderItem.reduce((item: any, index: any) => {
                return item
              }).product.name
            }
            - {scheduling?.qtyOrdered}t
          </div>
        </div>
      )}
    </div>
  )
}
