import React from 'react'
import { VehicleAttendanceCard } from '../VehicleAttendanceCard'
import { Queue } from '../../../../../../domain'

interface TabAttendanceContentProps {
  queueVehiclesAttendance: Queue[]
  isExpeditionViewer: boolean
  isConciergeViewer: boolean
  shippingCompanyId: number | null
  companyId: string | number | null

  handleQueueTime: (queue: Queue) => number
}

export function TabAttendanceContent({
  queueVehiclesAttendance,
  isExpeditionViewer,
  isConciergeViewer,
  shippingCompanyId,
  handleQueueTime,
  companyId
}: TabAttendanceContentProps) {
  return (
    <div className='list__vehicle__attendance'>
      {queueVehiclesAttendance.map(item => {
        const queueTime = handleQueueTime(item)

        const notExpeditionViewerAndHasCompany =
          !isExpeditionViewer && !isConciergeViewer && !shippingCompanyId

        return (
          <VehicleAttendanceCard
            key={item.id}
            item={item}
            queueTime={queueTime}
            notExpeditionViewerAndHasCompany={notExpeditionViewerAndHasCompany}
            companyId={companyId}
          />
        )
      })}
    </div>
  )
}
