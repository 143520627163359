import io from 'socket.io-client'
import { DriverCalled } from './pages/DriversTV/DriversTV'

interface CheckInCallResponse {
  type: 'success' | 'error'
  content: string
  driverCalled: DriverCalled | null
}

class WebSocketClient {
  private static instance: WebSocketClient
  private socket: SocketIOClient.Socket

  private constructor() {
    this.socket = io(`${process.env.REACT_APP_API}`)
    this.setupListeners()
  }

  public static getInstance(): WebSocketClient {
    if (!WebSocketClient.instance) {
      WebSocketClient.instance = new WebSocketClient()
    }
    return WebSocketClient.instance
  }

  private setupListeners() {
    this.socket.on('connect', () => {
      // console.log('Conectado ao WebSocket');
      // console.log(`Socket ID: ${this.socket.id}`);
    })

    this.socket.on('checkInCallResponse', (message: CheckInCallResponse) => {
      if (message.type === 'success') {
        // toast.success(message.content);
      } else if (message.type === 'error') {
        // toast.error(message.content);
      }
    })

    this.socket.on('disconnect', () => {
      // console.log(`Socket ID: ${this.socket.id}`);
    })

    this.socket.on('connect_error', () => {
      // console.error('Erro de conexão WebSocket:');
    })

    this.socket.on('reconnect_attempt', () => {
      // console.log('Tentativa de reconexão:');
    })

    this.socket.on('reconnect_failed', () => {
      // console.error('Falha na reconexão do WebSocket');
    })
  }

  public emit(event: string, data: any) {
    this.socket.emit(event, data)
  }

  public on(event: string, callback: (data: any) => void) {
    this.socket.on(event, callback)
  }

  public off(event: string, callback: (data: any) => void) {
    this.socket.off(event, callback)
  }
}

export default WebSocketClient.getInstance()
