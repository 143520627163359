import React from 'react'
import { PageLoading } from '../../../components/PageLoading/PageLoading'

export function DriverTVLoading() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background: '#fff'
      }}
    >
      <PageLoading loading />
    </div>
  )
}
