import santaMariaLogo from '../assets/santa_maria.png'
import smLogo from '../assets/sm.png'
import calmapLogo from '../assets/calmap.png'
import React from 'react'

export type TVLogoType = '1' | '2' | '26'

const logoMap: Record<TVLogoType, string> = {
  '1': smLogo,
  '2': calmapLogo,
  '26': santaMariaLogo
}

export function TVLogo({ logo, alt }: { logo: TVLogoType; alt?: string }) {
  return <img src={logoMap[logo]} alt={alt} />
}
