import React from 'react'
import { DataGrid, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid'
import { useInView } from 'react-intersection-observer'
import { ExportPDFButton } from '../../../../../../components/ExportPDFButton'
import { ExportCSVLinkButton } from '../../../../../../components/ExportCSVLinkButton'
import { LicensePlate } from '../../../../../../components/LicensePlate'
import { Export4DaysTableCSV } from '../Export4DaysTableCSV'
import { ServiceForecastDriver } from '../../../../../../components/ServiceForecastDriver'
import { toast } from 'react-toastify'
import { ExpedTableProps } from '../../expedTypes'
import cx from 'classnames'
import './styles.scss'

import soundIcon from '../../../assets/sound-icon.svg'
import truckIcon from '../../../../../../assets/images/icons/truck.svg'
import { CheckInCreateCall } from '../../../../../../domain'

export function QueueTable({
  index,
  getQueueByDay,
  shippingCompanyId,
  dates,
  days,
  rows,
  tolerance,
  isExpeditionViewer,
  isConciergeViewer,
  handleSelectScheduling,
  getToleranceTime,
  getTime,
  getLimitTime,
  checkInEnabled,
  tableKey,
  createCheckInCall
}: ExpedTableProps) {
  const { ref, inView } = useInView({
    threshold: 0
  })

  function onClickViewDetails(tableRow: any) {
    if (isExpeditionViewer || isConciergeViewer) {
      return
    }

    if (checkInEnabled && !tableRow.row.checkInActive) {
      toast.warn('motorista precisa fazer check-in')

      return
    }

    if (!shippingCompanyId) {
      handleSelectScheduling(tableRow)
    }
  }

  function handleCreateCheckinCall(data: CheckInCreateCall) {
    if (createCheckInCall) {
      createCheckInCall(data)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      hide: true,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`${
              params.row.isActive ? 'active position' : 'position'
            }`}
          >
            {params.row.id}
          </div>
        )
      }
    },
    {
      field: 'position__tolerance',
      headerName: '',
      width: 62,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { position, toleranceTime, isInTolerance, checkInActive } =
          params.row

        const modeTolerance = !checkInActive && isInTolerance

        return (
          <ServiceForecastDriver
            position={position}
            modeTolerance={modeTolerance}
            toleranceTime={toleranceTime}
            modeCheckIn={checkInActive}
          />
        )
      }
    },
    {
      field: 'licensePlate',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { isInTolerance, checkInActive } = params.row
        const themeColor = checkInActive
          ? '#4598C4'
          : isInTolerance
          ? '#fc8f00'
          : undefined

        return (
          <LicensePlate
            licensePlate={params.row.licensePlate}
            themeColor={themeColor}
          />
        )
      }
    },
    {
      field: 'driver',
      headerName: '',
      sortable: false,
      width: 360,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='driver__labels'>
              {params?.row?.scheduling?.order && (
                <span className='label__order'>Pedido</span>
              )}

              {params?.row?.isFreelancer === true && (
                <span className='label__freelancer'>Autônomo</span>
              )}

              {params?.row?.isInTolerance === true && (
                <span className='label__tolerance'>
                  Tolerância
                  <div className='dot'></div>
                  {getToleranceTime(params.row)}
                </span>
              )}
            </div>

            <div className='queue-driver__name' title={params.row.driverName}>
              {params.row?.driverName}
            </div>
          </div>
        )
      }
    },
    {
      field: 'actions',
      headerName: '',

      renderCell: params => {
        return (
          <div className='table-actions'>
            <button
              title='ver detalhes'
              className='view-details-button'
              onClick={() => onClickViewDetails(params)}
            >
              <img src={truckIcon} alt='' />
            </button>

            {tableKey === 'courtyard' && (
              <button
                title='criar chamado'
                className='checkin-call-button'
                onClick={() =>
                  handleCreateCheckinCall({
                    id: params.row.checkInId,
                    isCalling: true
                  })
                }
              >
                <img src={soundIcon} alt='' />
              </button>
            )}
          </div>
        )
      }
    }
  ]

  const columnsShipping: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      hide: true,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`${
              params.row.isActive ? 'active position' : 'position'
            }`}
          >
            {params.row.id}
          </div>
        )
      }
    },
    {
      field: 'position__tolerance',
      headerName: '',
      width: 62,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { position, toleranceTime, isInTolerance } = params.row
        const date = new Date(toleranceTime)
        const hours = date.getHours()
        const minutes = date.getMinutes()

        return (
          <div
            className={`queue-table__row__position ${
              isInTolerance && 'mode-tolerance'
            }`}
          >
            <span>{position}</span>
            <time className='markup__time'>
              {hours}:{minutes}
            </time>
          </div>
        )
      }
    },
    {
      field: 'licensePlate',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`
                ${params.row.isActive === true ? 'active' : ''}
                ${params.row.isInTolerance === true ? 'is-tolerance' : ''}
              `}
          >
            <div className='license-plate-field'>
              <div>{params.row.licensePlate}</div>
            </div>
          </div>
        )
      }
    },
    {
      field: 'column2',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='status_card'>
              <span className='label__legend'>
                <div className='content'>
                  Motorista
                  {params.row.freelancer === true && (
                    <span className='label__freelancer'>Autônomo</span>
                  )}
                  {params.row.isInTolerance === true && (
                    <span className='label__tolerance'>
                      Tolerância
                      <div className='dot'></div>
                      {getToleranceTime(params.row)}
                    </span>
                  )}
                </div>
              </span>
              <span className='label__value'>
                {params.row?.driverName
                  ?.replace(' de ', ' ')
                  ?.replace(' das ')
                  ?.replace(' do ')
                  ?.split(' ')
                  ?.slice(0, 3)
                  ?.join(' ')}
              </span>
            </div>

            <div className='status_card'>
              <span className='label__legend'>Cliente</span>
              <span className='label__value'>
                {params.row?.scheduling?.order?.customer?.name}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      field: 'column3',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='status_card'>
              <span className='label__legend'>Previsão</span>
              <span className='label__value'>
                {params.row.isInTolerance
                  ? getToleranceTime(params.row)
                  : getTime(params.row)}
              </span>
            </div>

            <div className='status_card'>
              <span className='label__legend'>Pedido</span>
              <span className='label__value'>
                {' '}
                {params.row.scheduling?.order?.code}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      field: 'column4',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='status_card'>
              <span className='label__legend'>Tolerância</span>
              <span className='label__value'>{getLimitTime(params.row)}</span>
            </div>

            <div className='status_card'>
              <span className='label__legend'>Capacidade</span>
              <span className='label__value'>
                {params.row?.truck?.netCapacity}
              </span>
            </div>
          </div>
        )
      }
    }
  ]

  const date: string = dates[index]
  const dateArray: string[] = date?.split('/')
  const month = dateArray?.[0]
  const day = dateArray?.[1]

  const columnsExport = [
    { headerName: 'Id' },
    { headerName: 'Posição' },
    { headerName: 'Placa' },
    { headerName: '' },
    { headerName: 'Atendimento' },
    { headerName: 'Tolerância' }
  ]

  const rowsToExport = rows.map((row: any) => {
    return {
      id: row.id,
      position: row.position,
      licensePlate: row.licensePlate,
      random: '',
      attendanceTime: row.time,
      ToleredTime: row.toleranceTime
    }
  })

  return (
    <>
      <div
        ref={ref}
        key={index}
        data-table-position={index + 1}
        className={cx('table', {
          'first-table': index === 0,
          shipping__mode: shippingCompanyId,
          normal: !shippingCompanyId
        })}
      >
        <div id={`table${index}`} className='queue-table__time'>
          <span>{month + '/'}</span>
          <sup>{day}</sup>
        </div>

        {(index && inView) || !index ? (
          <DataGrid
            autoHeight={true}
            initialState={{
              pagination: {
                pageSize: 10
              }
            }}
            pageSize={100}
            rows={rows}
            columns={Number(shippingCompanyId) ? columnsShipping : columns}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            headerHeight={30}
            disableColumnMenu
            getRowId={row => `${row?.id}-${row?.truck?.id}`}
          />
        ) : (
          <></>
        )}

        {!shippingCompanyId && !isExpeditionViewer && !isConciergeViewer && (
          <div className='export-button'>
            <ExportPDFButton
              columns={columnsExport}
              rows={rowsToExport}
              tolerance={tolerance}
              day={dates[index]}
            />

            <ExportCSVLinkButton rows={rows} tolerance={tolerance} />

            <Export4DaysTableCSV
              days={days}
              dates={dates}
              onGenerateRows={getQueueByDay}
              tolerance={tolerance}
            />
          </div>
        )}
      </div>
    </>
  )
}
