import React, { useEffect, useState } from 'react'
import styles from '../styles.module.css'
import webSocketClient from '../../../webSocketClient'

interface WSMessageResponse {
  type: 'success'
  content: string
  inAttendance: number
}

export function CountAttendance({
  attendeds,
  refetchDriverList
}: {
  attendeds: number

  refetchDriverList: () => Promise<void>
}) {
  const [count, setCount] = useState(attendeds)

  useEffect(() => {
    const handleWebSocketResponse = (message: WSMessageResponse) => {
      setCount(message.inAttendance)
      refetchDriverList()
    }

    webSocketClient.on('countInAttendanceResponse', handleWebSocketResponse)

    return () => {
      webSocketClient.off('countInAttendanceResponse', handleWebSocketResponse)
    }
  }, [refetchDriverList])

  return (
    <div className={styles.attended}>
      <span>Em atendimento</span>
      <p>{count}</p>
    </div>
  )
}
