import React, { useEffect, useState } from 'react'
import api from '../../../../services/api'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import './styles.scss'

const BarTop = () => {
  const [dateBrwoser, setDateBrowser] = useState('')

  useEffect(() => {
    api
      .get('settings/date-time')
      .then(response => setDateBrowser(response.data))
  }, [])

  const date = dateBrwoser ? parseISO(dateBrwoser) : new Date()

  const formattedDate = format(
    date,
    "eee, dd 'de' MMMM 'de' yyyy '•' HH'h'mm",
    {
      locale: ptBR
    }
  )

  return (
    <div className='BarTop'>
      <div className='BarTopContent'>
        {!isNaN(date.getTime()) && <h2>{formattedDate}</h2>}
      </div>
    </div>
  )
}

export default BarTop
