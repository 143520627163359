import React from 'react'

export function WarningActionsBlocked() {
  return (
    <p
      style={{
        fontSize: '20px',
        fontWeight: 400
      }}
    >
      Sua posição já está dentro da{' '}
      <b>janela de atendimento de 6h antes da previsão apresentada</b>, logo não
      é mais possível sair da fila.
    </p>
  )
}
