import React from 'react'

type Props = {
  licensePlate: string
  isTolerance: boolean
}

export function UpdateQueueWarning({ isTolerance, licensePlate }: Props) {
  return (
    <>
      <div className={`status-license-plate ${isTolerance && 'tolerance'}`}>
        {licensePlate}
      </div>

      <div>
        <span className='gray-color'>
          <b>Atenção Motorista!</b> <br />
          Acompanhe por aqui a atualização de sua posição na fila.
        </span>
      </div>
    </>
  )
}
