import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { useAuth } from '../../../../contexts/auth'

import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import goback from '../../../../assets/images/back.png'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import logo from '../../../../assets/images/logo-white-2.svg'
import logo_color from '../../../../assets/images/logo-dark-1_a.svg'
import api from '../../../../services/api'
import { ModalSignOutQueue } from '../../Driver/components/ModalSignOutQueue'
import { useDriverContext } from '../../../../contexts/DriverContext/useDriverContext'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: useTheme().spacing(2)
    },
    title: {
      flexGrow: 1
    }
  })
)
interface AppTooBar {
  pathToBack: string
  isStatic: boolean
}

interface HasDriverInQueueResponse {
  hasDriver: boolean
}

const AppTooBar: React.FC<AppTooBar> = ({ pathToBack, isStatic }) => {
  const { LogoutExped, driverLogout, role } = useAuth()

  const { driverQueuePosition } = useDriverContext()

  const [hasDriverInQueue, setHasDriverInQueue] = useState(false)
  const [isOpenModalOutQueue, setIsOpenModalOutQueue] = useState(false)

  let history = useHistory()
  const classes = useStyles()

  const [auth] = React.useState(true)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const driverId = sessionStorage.getItem('@AppDriver:id')
  const user = sessionStorage.getItem('@App:user')

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function logout() {
    if (window.location.pathname === '/expedicao') LogoutExped()
    else driverLogout()
  }

  function handleEdit() {
    if (hasDriverInQueue) {
      setIsOpenModalOutQueue(true)
    } else {
      if (!role) {
        history.push('/app/edit')
      }
    }
  }

  function handleHistory() {
    history.push('/app/history')
  }

  async function checkHasDriverInQueue() {
    try {
      const response = await api.get<HasDriverInQueueResponse>(
        `queue/hasDriver/${driverId}`
      )
      const { hasDriver } = response.data

      setHasDriverInQueue(hasDriver)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (driverId) {
      checkHasDriverInQueue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId])

  return (
    <div>
      <div className={classes.root}>
        {!isStatic && <div className='static-header-container'></div>}
        <AppBar position={isStatic ? 'static' : 'fixed'}>
          <Toolbar>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
            >
              {window.location.pathname !== '/app' &&
                window.location.pathname !== '/' && (
                  <Link className='back-icon' to={pathToBack}>
                    <img id='goback' src={goback} alt='' />

                    <div className='back-text'>Voltar</div>
                  </Link>
                )}
            </IconButton>

            <div className='img'>
              {window.location.pathname !== '/expedicao' && (
                <img
                  src={logo}
                  alt=''
                  style={{
                    width: 266
                  }}
                />
              )}
              {window.location.pathname === '/expedicao' && (
                <img
                  src={logo_color}
                  alt=''
                  style={{
                    width: 266
                  }}
                />
              )}
            </div>

            {auth && (
              <div>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <Avatar>{user?.slice(0, 1).toUpperCase()}</Avatar>
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={handleEdit}
                    disabled={driverQueuePosition.isDriverActionsBlocked}
                  >
                    Perfil
                  </MenuItem>
                  {driverId && (
                    <MenuItem onClick={handleHistory}>Histórico</MenuItem>
                  )}
                  <MenuItem onClick={logout}>Sair</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>

      <ModalSignOutQueue
        open={isOpenModalOutQueue}
        onClose={() => setIsOpenModalOutQueue(false)}
      />
    </div>
  )
}

export default AppTooBar
