import React from 'react'
import { LicensePlate } from '../../../components/LicensePlate'
import successIcon from '../assets/success-icon.svg'
import cx from 'clsx'
import styles from '../styles.module.css'
import { TV } from '../DriversTV'
import { format } from 'date-fns'

type DriverCardProps = TV

export function DriverTVCard({
  driverTv: { queue, called_at },
  inAttendance
}: DriverCardProps) {
  const formattedDate = format(new Date(called_at), "dd/MM '•' HH'h'mm")

  return (
    <div
      className={cx(styles['driver-card'], {
        [styles['in-attendance']]: inAttendance
      })}
    >
      <time>{formattedDate}</time>

      <LicensePlate
        licensePlate={queue?.truck?.licensePlate}
        fontSize='16px'
        themeColor='#000'
      />

      <p className='truncate'>{queue.driver.name}</p>

      <div className={styles.icon}>
        <img src={successIcon} alt='' />
      </div>
    </div>
  )
}
