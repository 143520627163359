import React from 'react'
import { QueueTable } from '../ExpeditionTables/QueueTable'
import { ExpedTabTableProps } from '../../expedTypes'
import { QueueByDay } from '../../../../../../domain'

interface TabCourtyardContentProps extends ExpedTabTableProps {
  queueVehiclesCourtyard: QueueByDay[]
}

export function TabCourtyardContent({
  queueVehiclesCourtyard,
  days,
  dates,
  tolerance,
  getQueueByDay,
  getTime,
  isExpeditionViewer,
  isConciergeViewer,
  shippingCompanyId,
  getToleranceTime,
  getLimitTime,
  handleSelectScheduling,
  checkInEnabled,
  tableKey,
  createCheckInCall
}: TabCourtyardContentProps) {
  const queueEmpty = queueVehiclesCourtyard.length === 0

  if (queueEmpty) {
    return <></>
  }

  return (
    <QueueTable
      rows={queueVehiclesCourtyard}
      days={days}
      index={0}
      dates={dates}
      tolerance={tolerance}
      shippingCompanyId={shippingCompanyId}
      isExpeditionViewer={isExpeditionViewer}
      isConciergeViewer={isConciergeViewer}
      getQueueByDay={getQueueByDay}
      getTime={getTime}
      getLimitTime={getLimitTime}
      getToleranceTime={getToleranceTime}
      handleSelectScheduling={handleSelectScheduling}
      checkInEnabled={checkInEnabled}
      tableKey={tableKey}
      createCheckInCall={createCheckInCall}
    />
  )
}
