import webSocketClient from '../../../webSocketClient'

export function useQueueUpdateCountAttendance() {
  function updateCountAttendance(companyId: number) {
    try {
      if (!companyId) return

      webSocketClient.emit('updateCountInAttendance', {
        company: {
          id: companyId
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return { updateCountAttendance }
}
