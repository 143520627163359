import React, { useEffect, useState } from 'react'
import './styles.css'

export type CircularTimerProps = {
  wholeTime: any
  tolerance: any
  initialDate: any
  finalDate: any
}

function CircularTimer({
  wholeTime,
  tolerance,
  initialDate,
  finalDate
}: CircularTimerProps) {
  const [loaded, setIsloaded] = useState<any>()
  const [isStarted, setIsStarted] = useState(false)

  useEffect(() => {
    setIsloaded(true)

    setIsStarted(true)
  }, [])

  if (isStarted) {
    timer(wholeTime)
  }

  //circle start
  let progressBar: any = document!.querySelector('.e-c-progress')!
  let pointer: any = document.getElementById('e-pointer')
  let length = Math.PI * 2 * 100

  if (loaded === true) {
    progressBar.style.strokeDasharray = length
  }

  function update(value: any, timePercent: any) {
    if (loaded === true) {
      progressBar.style.strokeDasharray = 630

      progressBar.style.strokeDashoffset = 630 - 630 * (value / timePercent)

      pointer.style.transform = `rotate(${(360 * value) / timePercent}deg)`
    }
  }

  //circle ends
  const displayOutput: any = document.querySelector('.display-remain-time')
  const setterBtns = document.querySelectorAll('button[data-setter]')

  update(wholeTime, wholeTime) //refreshes progress bar
  displayTimeLeft(wholeTime)

  function changeWholeTime(seconds: any) {
    if (wholeTime + seconds > 0) {
      wholeTime += seconds
      update(wholeTime, wholeTime)
    }
  }

  for (var i = 0; i < setterBtns.length; i++) {
    //no-loop-func
    setterBtns[i].addEventListener('click', function (event: any) {
      var param = event.dataset.setter
      switch (param) {
        case 'minutes-plus':
          changeWholeTime(1 * 60)
          break
        case 'minutes-minus':
          changeWholeTime(-1 * 60)
          break
        case 'seconds-plus':
          changeWholeTime(1)
          break
        case 'seconds-minus':
          changeWholeTime(-1)
          break
      }
      //displayTimeLeft(wholeTime);
    })
  }

  function returnToleranceTime(attendanceTime: any) {
    /***** */

    let toleranceHours = Number(tolerance?.slice(0, 2)) * 60 * 60 * 1000
    let toleranceMinutes = Number(tolerance?.slice(3, 5)) * 60 * 1000
    let time =
      new Date(attendanceTime.date).getTime() +
      (toleranceHours + toleranceMinutes)

    //let activedTolerance =
    /**** */

    let initialTime = Math.round(time - new Date(attendanceTime.date).getTime())
    let timeleft = Math.round(time - new Date().getTime())

    if (timeleft > 0) {
      update(timeleft, initialTime)
      displayTimeLeft(timeleft)
    }

    let days: any = Math.floor(timeleft / (1000 * 60 * 60 * 24))
    let hours: any = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    let minutes: any = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
    let seconds: any = Math.floor((timeleft % (1000 * 60)) / 1000)

    if (seconds < 10 && seconds > 0) {
      seconds = '0' + seconds
    }
    if (minutes < 10 && minutes > 0) {
      minutes = '0' + minutes
    }
    if (hours < 10 && hours > 0) {
      hours = '0' + hours
    }
    if (days < 10 && days > 0) {
      days = '0' + days
    }

    let countDown = `${hours}:${minutes}:${seconds}`

    if (days > 0) {
      countDown = `${days}:${hours}:${minutes}:${seconds}`
    } else {
      countDown = `${hours}:${minutes}:${seconds}`
    }

    if (Number(minutes) < 0 || Number(hours) < 0 || Number(days) < 0) {
      return 'Esgotado'
    }

    if (minutes > 0) {
      return countDown
    } else {
      return countDown
    }
  }

  function timer(seconds: any) {
    //counts time, takes seconds

    setInterval(function () {
      returnToleranceTime(initialDate)
    }, 1000)
  }

  function displayTimeLeft(timeLeft: any) {
    //displays time on the input

    let hour = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)

    let displayString = `${hour < 10 ? '0' : ''}${hour}:${
      minutes < 10 ? '0' : ''
    }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`

    if (hour < 0) {
      displayString = 'Esgotado'
    }

    if (loaded === true) {
      displayOutput.textContent = displayString
    }

    //update(timeLeft, wholeTime);
  }

  return (
    <div className='timer'>
      <div className='circle'>
        <svg
          width='300'
          viewBox='0 0 220 220'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g transform='translate(110,110)'>
            <circle r='100' className='e-c-base' />
            <g transform='rotate(-90)'>
              <circle r='100' className='e-c-progress' />
              <g id='e-pointer'>
                <circle cx='100' cy='0' r='8' className='e-c-pointer' />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className='controlls'>
        <div className='display-remain-time'>00:30</div>
      </div>
      <div className='message'>
        Tempo restante para o cancelamento da posição
      </div>
    </div>
  )
}

export default CircularTimer
