import React from 'react'
import { QueueTable } from '../ExpeditionTables/QueueTable'
import { ExpedTabTableProps } from '../../expedTypes'

type TabQueueContentProps = ExpedTabTableProps

export function TabQueueContent({
  days,
  dates,
  tolerance,
  getQueueByDay,
  isExpeditionViewer,
  isConciergeViewer,
  shippingCompanyId,
  handleSelectScheduling,
  checkInEnabled,
  getLimitTime,
  getTime,
  getToleranceTime,
  tableKey,
  createCheckInCall
}: TabQueueContentProps) {
  return (
    <div>
      {days.map((_, dayIndex) => {
        const rows = getQueueByDay(dayIndex)

        if (rows?.length > 0) {
          return (
            <QueueTable
              key={`table-${dayIndex}`}
              rows={rows}
              days={days}
              index={dayIndex}
              dates={dates}
              tolerance={tolerance}
              shippingCompanyId={shippingCompanyId}
              isExpeditionViewer={isExpeditionViewer}
              isConciergeViewer={isConciergeViewer}
              getQueueByDay={getQueueByDay}
              getTime={getTime}
              getLimitTime={getLimitTime}
              getToleranceTime={getToleranceTime}
              handleSelectScheduling={handleSelectScheduling}
              checkInEnabled={checkInEnabled}
              tableKey={tableKey}
              createCheckInCall={createCheckInCall}
            />
          )
        }

        return null
      })}
    </div>
  )
}
