import React from "react";

import { AiOutlineWarning } from "react-icons/ai";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import api from "../../../../../../services/api";

import { Button } from "@material-ui/core";
import { ModalToleranceType } from "../../ExpeditionHome";

interface ToleranceWarningModalProps {
  modalSuspend: any;
  setModalSuspend: any;
  modalToleranceType: ModalToleranceType;
  companyId: any;
  updateAttendances: any;
  isClosingAttendance: any;
}

const textModal: Record<ModalToleranceType, string> = {
  "enable-tolerance": "Ao ativar a tolerância veículos com tempo esgotado cairão automaticamente. Deseja reativar a tolerância?",
  "disable-tolerance": "Ao desativar a tolerância, a fila virtual passa a não descartar veículos atrasados automaticamente e, assim, ficando com o processo mais lento. Deseja continuar?",
  "end-attendance": "Ao encerrar o atendimento os carros excedentes com previsão de atendimento para hoje serão deslocados para o dia seguinte, deseja concluir o atendimento por hoje?",
}

export const ToleranceWarningModal: React.FC<ToleranceWarningModalProps> = ({
  modalToleranceType,
  modalSuspend,
  setModalSuspend,
  companyId,
  updateAttendances,
  isClosingAttendance,
}) => {
  const handleToggleTolerance = async (tolerance: boolean) => {
    try {
      await api.post("settings/toggle-tolerance", {
        company: companyId,
        tolerance,
      })

      setTimeout(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)
    }
  }

  const buttonModal: Record<ModalToleranceType, React.ReactNode> = {
    "enable-tolerance":
      <Button onClick={() => handleToggleTolerance(true)}>
        Ativar
      </Button>
    ,
    "disable-tolerance":
      <Button onClick={() => handleToggleTolerance(false)}>
        Desativar
      </Button>
    ,
    "end-attendance":
      <Button
        disabled={isClosingAttendance}
        onClick={updateAttendances}
      >
        {isClosingAttendance ? "Encerrando..." : "Encerrar"}
      </Button>
  }

  return (
    <Box className="modal expedition">
      <Modal
        open={modalSuspend}
        onClose={() => setModalSuspend(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className=" suspend-warning body">
          <Box className="container">
            <Box>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="advice">
                  <div>
                    <div className="warn-icon">
                      <AiOutlineWarning />
                    </div>
                    <div className="bold-text">ATENÇÃO! </div>
                  </div>
                    {!!textModal[modalToleranceType] && (
                      <div>
                        {textModal[modalToleranceType]}
                      </div>
                    )}
                </div>
              </Typography>
            </Box>

            <Box className="actions buttons">
              <Button onClick={() => setModalSuspend(false)}>Cancelar</Button>

              {buttonModal[modalToleranceType]}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
