import React, { useState } from 'react'
import { BackVehicleToQueueModal } from '../../../../../../components/BackVehicleToQueueModal'
import { CompleteVehicleChargingModal } from '../../../../../../components/CompleteVehicleChargingModal'
import './styles.scss'

interface VehicleAttendanceCardProps {
  item: any
  queueTime: number
  notExpeditionViewerAndHasCompany: boolean
  companyId: string | number | null
}

type ModalKeys = 'back-to-queue' | 'complete-vehicle-charging'

export function VehicleAttendanceCard({
  item,
  queueTime,
  notExpeditionViewerAndHasCompany,
  companyId
}: VehicleAttendanceCardProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalKey, setModalKey] = useState<ModalKeys | null>(null)

  function handleOpenModal(modalKey: ModalKeys) {
    setModalIsOpen(true)

    setModalKey(modalKey)
  }

  function handleCloseModal() {
    setModalIsOpen(false)
    setModalKey(null)
  }

  return (
    <div className='vehicle__attendance__card'>
      <div className='vehicle__attendance__card__content'>
        <div>
          <div className='attendance__card__license-plate'>
            {item.scheduling?.truck?.licensePlate}
          </div>

          <div className='attendance__queue__time'>
            <time>{queueTime}</time> <span>min</span>
          </div>
        </div>

        {notExpeditionViewerAndHasCompany && (
          <div className='attendance__buttons-actions'>
            <button
              type='button'
              className='button__back-queue'
              onClick={() => handleOpenModal('back-to-queue')}
            >
              Voltar para fila
            </button>
            <button
              type='button'
              className='button__end-attendance'
              onClick={() => handleOpenModal('complete-vehicle-charging')}
            >
              Encerrar atendimento
            </button>
          </div>
        )}
      </div>

      {modalKey === 'back-to-queue' && (
        <BackVehicleToQueueModal
          open={modalIsOpen}
          onCloseModal={handleCloseModal}
          vehicle={item}
          companyId={companyId}
        />
      )}

      {modalKey === 'complete-vehicle-charging' && (
        <CompleteVehicleChargingModal
          open={modalIsOpen}
          vehicle={item}
          onCloseModal={handleCloseModal}
          companyId={companyId}
        />
      )}
    </div>
  )
}
