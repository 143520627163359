import { useEffect, useState } from 'react'
import { QueuePositionDriverApi } from '../driverTypes'
import { driverServices } from '../driverServices'

export type DriverGetQueuePositionData = {
  data: QueuePositionDriverApi | null
  isError: boolean
  isLoading: boolean
  isDriverActionsBlocked: boolean
}

/**
 * @property {string} [driverId]
 */
export function useDriverGetQueuePosition(
  driverId: string
): DriverGetQueuePositionData {
  const [data, setData] = useState<QueuePositionDriverApi | null>(null)
  const [isDriverActionsBlocked, setIsDriverActionsBlocked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  async function fetchData() {
    try {
      setLoading(true)
      const driverData = await driverServices.getQueuePositionDriver(
        Number(driverId)
      )

      const queueDate = driverData.queue.date
      const isDriverActionsBlocked = blockDriverActions({
        appointmentTime: queueDate
      })

      setIsDriverActionsBlocked(isDriverActionsBlocked)

      setData(driverData)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId])

  return {
    data,
    isDriverActionsBlocked,
    isError: error,
    isLoading: loading
  }
}

function blockDriverActions({
  appointmentTime
}: {
  appointmentTime: string
}): boolean {
  const now = new Date().getTime()
  const appointment = new Date(appointmentTime).getTime()

  const timeDifference = appointment - now // in ms
  const minutes = Math.ceil(timeDifference / (1000 * 60))

  const SIX_HOURS_IN_MINUTES = 6 * 60 // 360 min
  const isDriverActionsBlocked = minutes <= SIX_HOURS_IN_MINUTES
  return isDriverActionsBlocked
}
