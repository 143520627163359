/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useStopwatch } from 'react-timer-hook'
import { useParams } from 'react-router-dom'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { toast } from 'react-toastify'

import { useAuth } from '../../../../contexts/auth'
import api from '../../../../services/api'

import CryptoJS from 'crypto-js'

import { OrderDetails } from '../../components/OrderDetails'
import { ConnectionCheckModal } from './components/ConnectionCheckModal'
import { ExpeditionQueueStatus } from './components/ExpeditionQueueStatus/ExpeditionQueueStatus'
import { FilterTableByDaySidebar } from './components/FilterTableByDaySidebar'
import { ExpeditionDelayReason } from './components/ExpeditionDelayReason/ExpeditionDelayReason'
import { AttendanceWithDelayStatus } from './components/AttendanceWithDelayStatus'
import { ToleranceWarningModal } from './components/ToleranceWarningModal'
import { ExpeditionActions } from './components/ExpeditionActions'
import { LastUpdateExpedition } from './components/LastUpdateExpedition'
import { ExpeditionSkeleton } from './components/ExpeditionSkeleton'
import { ShippingCompanyTable } from './components/ExpeditionTables/ShippingCompanyTable'
import AppToolBar from '../../components/AppToolBar'

import { TabsHeading } from './components/Main/TabsHeading'
import { TabAttendanceContent } from './components/Main/TabAttendanceContent'
import { TabQueueContent } from './components/Main/TabQueueContent'

import caminhaoIcon from '../../../../assets/images/icons/truck.svg'
import _Iniciar from '../../../../assets/images/Atendimento_Finalizado.svg'

import {
  companyServices,
  driverCheckInServices,
  queueServices,
  Queue,
  QueueByDay,
  QueuePosition,
  ServiceStatus,
  SettingsCompany,
  CheckInCreateCall
} from '../../../../domain'
import { localStorageUtils } from '../../../../utils'
import { PageLoading } from '../../../../components/PageLoading/PageLoading'
import {
  MAX_QUEUES_PER_DAY,
  TabQueueOptions,
  THREEMINUTES_IN_MILISECONDS_UPDATE_EXPED
} from './expedTypes'

import { TabCourtyardContent } from './components/Main/TabCourtyardContent'
import { TabFilters } from './components/Main/TabFilters'
import { differenceInMilliseconds } from 'date-fns'
import './styles.scss'
import { SearchVehicleByLicensePlate } from './components/SearchVehicleByLicensePlate'
import webSocketClient from '../../../../webSocketClient'

export type QueueStatusService = ServiceStatus
interface Option {
  label: string
  value: string
  time: string
}

interface RouteParams {
  shippingCompanyIdEncrypted: any
}

export interface TruckToAttend {
  queue: Queue
  checkinId?: number | null
}

export type ModalToleranceType =
  | 'enable-tolerance'
  | 'disable-tolerance'
  | 'end-attendance'

const initializeQueueStatus: QueueStatusService = {
  totals: 0,
  totalsToday: 0,
  attended: 0,
  missing: 0,
  totalTransportedToday: 0,
  dropouts: 0
}

const initializeSettingsCompany: SettingsCompany = {
  id: 0,
  programmedTotal: 0,
  timeAttendence: '',
  start: '',
  end: '',
  startInterval: '',
  endInterval: '',
  googleMaps: '',
  weekDays: '',
  hour_entry_Limit: 0,
  queue_edit_hours_limit: 0,
  scheduling_day_limit: 0,
  enable_missing_validation: false,
  missing_limit: 0,
  missing_limit_after_block: 0,
  missing_validation_period: 0,
  enableTolerance: false,
  tolerance: '',
  checkinIp: '',
  enableRecaptcha: false,
  recaptchaSecret: null,
  recaptchaSite: null,
  enableQueueSave: false,
  enableCheckin: false,
  delay: []
}

export function ExpeditionHome() {
  library.add(fas)

  const { company, role } = useAuth()

  const companyId = localStorageUtils.get('@App:companyId')!
  const storageShippingCompanyId = localStorageUtils.get(
    '@App:shippingCompanyId'
  )!

  const { shippingCompanyIdEncrypted } = useParams<RouteParams>()

  const [truckToAttend, setTruckToAttend] = useState<TruckToAttend | null>(null)

  const [days, setDays] = useState<string[]>([])
  const [dates, setDates] = useState<string[]>([])

  const [queues, setQueues] = useState<Queue[]>([])

  const [queuesByDays, setQueuesByDays] = useState<QueueByDay[][]>([])

  const [firtsFourDaysQueues, setFirtsFourDaysQueues] = useState<
    QueueByDay[][]
  >([])

  const [queueVehiclesAttendance, setQueueVehiclesAttendance] = useState<
    Queue[]
  >([])

  const [queueVehiclesCourtyard, setQueueVehiclesCourtyard] = useState<
    QueueByDay[]
  >([])

  const [queueStatus, setQueueStatus] = useState(initializeQueueStatus)
  const [settingsCompany, setSettingsCompany] = useState(
    initializeSettingsCompany
  )

  const [searchVehicleQueue, setSearchVehicleQueue] = useState('')
  const [searchVehicleCourtyard, setSearchVehicleCourtyard] = useState('')

  const [activedQueues, setActivedQueues] = useState<any>([])

  const [isUserConnected, setIsUserConnected] = useState(() => {
    const isConnected = sessionStorage.getItem('@checkedConnect')

    if (isConnected) {
      return Boolean(isConnected)
    }

    return false
  })

  const [isOpenModalConnected, setIsOpenModalConnected] = useState(false)
  const [isClosingAttendance, setIsClosingAttendance] = useState(false)

  const [disable, setDisable] = useState(1)
  const [confirm, setConfirm] = useState(0)
  const [modalSuspend, setModalSuspend] = useState<any>(false)
  const [attendance, setAttendance] = useState(
    localStorage.getItem('attendance')
  )

  const [delay, setDelay] = useState(1)

  const [iconName, setIconName] = useState<any>('')
  const [delayName, setDelayName] = useState<any>('')

  const [delayValue, setDelayValue] = useState<Option>({
    value: '',
    label: '',
    time: ''
  })

  const [delayTime, setDelayTime] = useState('00:00')
  const [delayStatus, setDelayStatus] = useState(false)
  const [delayedTime, setDelayedTime] = useState(0)

  const [loading, setLoading] = useState(true)

  const { minutes } = useStopwatch({ autoStart: true })

  const [modalToleranceType, setModalToleranceType] =
    useState<ModalToleranceType>('disable-tolerance')

  const [tabSelected, setTabSelected] = useState<TabQueueOptions>('queue')

  const [timestamp, setTimestamp] = useState(() => {
    const time = sessionStorage.getItem('@reloadedTimestamp')

    if (time) {
      return time
    }
    return ''
  })

  const queuesIsEmpty = queues.length === 0
  const queuesTotal = queues.length
  const attendanceTotal = queueVehiclesAttendance.length

  const shippingCompanyId =
    shippingCompanyIdEncrypted?.length > 20
      ? Number(
          CryptoJS.AES.decrypt(
            shippingCompanyIdEncrypted
              ?.toString()
              .replaceAll('xMl3Jk', '+')
              .replaceAll('Por21Ld', '/')
              .replaceAll('Ml32', '='),
            'SecretCpf'
          )?.toString(CryptoJS.enc.Utf8)
        )
      : Number(storageShippingCompanyId) &&
        sessionStorage.getItem('@App:userRole') === 'Transportadora'
      ? Number(storageShippingCompanyId)
      : ''

  const userId = sessionStorage.getItem('@App:id') ?? company

  const isExpeditionViewer = role === 'Espectador'
  const isConciergeViewer = role === 'Portaria'

  const filteredQueuesByDays =
    searchVehicleQueue.length > 0
      ? queuesByDays.map(queueOfDay =>
          filterQueuesByLicensePlate(queueOfDay, searchVehicleQueue)
        )
      : firtsFourDaysQueues

  const filteredVehicleCourtyard =
    searchVehicleCourtyard.length > 0
      ? filterQueuesByLicensePlate(
          queueVehiclesCourtyard,
          searchVehicleCourtyard
        )
      : queueVehiclesCourtyard

  const checkinTotal = filteredVehicleCourtyard.length

  function handleCloseLoading() {
    setLoading(false)
  }

  function changeSearchVehicleQueue(licensePlate: string) {
    setSearchVehicleQueue(licensePlate)
  }

  function changeSearchVehicleCourtyard(licensePlate: string) {
    setSearchVehicleCourtyard(licensePlate)
  }

  function clearSearchesOfVehicles() {
    setSearchVehicleQueue('')
    setSearchVehicleCourtyard('')
  }

  async function handleSelectShippingCompany(_shippingCompanyId: number) {
    setLoading(true)

    if (!_shippingCompanyId) {
      await queryQueue()
      return
    }

    await queryQueue({
      customCompanyId: _shippingCompanyId
    })
  }

  function filterQueuesByLicensePlate(
    queuesOfDay: QueueByDay[],
    licensePlateToSearch: string
  ) {
    return queuesOfDay.filter(({ licensePlate }) =>
      licensePlate.toLowerCase().includes(licensePlateToSearch.toLowerCase())
    )
  }

  async function queryQueue(param?: { customCompanyId: number }) {
    let customShippingCompany: string

    if (param?.customCompanyId) {
      const { customCompanyId } = param
      customShippingCompany = customCompanyId.toString()
    } else {
      customShippingCompany = shippingCompanyId.toString()
    }

    const queuePositions = await queueServices.getQueueShippingCompanyPositions(
      customShippingCompany
    )

    const getFromValue = customShippingCompany ? 'shipping-company' : 'company'
    const getQueueFrom = customShippingCompany
      ? customShippingCompany
      : companyId

    const queues = await queueServices.getQueueCompany({
      from: getFromValue,
      companyId: getQueueFrom
    })

    const queuesActive = await queueServices.getQueueActiveCompany({
      from: getFromValue,
      companyId: getQueueFrom
    })

    const { days, dates } = generateDaysAndDatesFromQueue(queues)

    const queuesByDay = generateQueuesByDay(
      queues,
      days,
      queuesActive,
      queuePositions
    )

    const _firstFourDaysQueues = generateQueuesForFourDays(
      days,
      queuesByDay,
      getFromValue
    )

    setDays(days)
    setDates(dates)

    setQueues(queues)
    setQueuesByDays(queuesByDay)
    setQueueVehiclesAttendance(queuesActive)

    setFirtsFourDaysQueues(_firstFourDaysQueues)
    handleCloseLoading()
  }

  function generateQueuesForFourDays(
    _days: string[],
    _queuesByDays: QueueByDay[][],
    generateFrom: 'shipping-company' | 'company'
  ) {
    if (generateFrom === 'shipping-company') {
      return fillQueueForAllDays(_days, _queuesByDays)
    } else {
      return fillQueueFourDays(_days, _queuesByDays)
    }
  }

  function fillQueueForAllDays(_days: string[], _queuesByDays: QueueByDay[][]) {
    const arr: QueueByDay[][] = []

    for (let day = 0; day < _days.length; day++) {
      arr.push(_queuesByDays[day])
    }

    return arr
  }

  function fillQueueFourDays(_days: string[], _queuesByDays: QueueByDay[][]) {
    const arr: QueueByDay[][] = []

    for (let day = 0; day < MAX_QUEUES_PER_DAY && day < _days.length; day++) {
      arr.push(_queuesByDays[day])
    }

    return arr
  }

  function generateQueuesByDay(
    queues: Queue[],
    days: string[],
    queuesActive: Queue[],
    queuePositions: QueuePosition[]
  ) {
    const queuesByDay = days.map(day => {
      const queue = handleGetQueueByDay(
        queues,
        Number(day),
        queuesActive,
        queuePositions
      )
      return queue
    })

    return queuesByDay
  }

  function handleGetQueueByDay(
    queues: Queue[],
    day: number,
    queuesActive: Queue[],
    queuePositions: QueuePosition[]
  ) {
    let array: QueueByDay[] = []

    for (let index = 0; index < queues.length; index++) {
      const queue = queues[index]
      const scheduleDay = new Date(queue.date).getDate()

      const isActive = handleIsVehicleInQueueAttendance(queue.id, queuesActive)

      const isInTolerance = handleVehicleIsInTolerance(queue.date)
      const queuePosition = handleCreatePositionInQueue(queue, day, isActive)

      let position = queuePosition

      const lastQueue = array[array.length - 1]

      if (lastQueue?.isInTolerance && !isInTolerance) {
        localStorage.setItem('@last-position', String(Number(1)))
        position = 1 + '°'
      }

      if (storageShippingCompanyId && queuePositions[index]?.position) {
        position = queuePositions[index].position + '°'
      }

      if (scheduleDay === day && !isActive) {
        let licensePlate = queue.scheduling
          ? queue.scheduling.truck?.licensePlate
          : queue.truck?.licensePlate
        licensePlate = licensePlate?.split('-').join('')

        const newQueue: QueueByDay = {
          id: queue.id,
          driverName: queue?.driver?.name || '',
          position: position || '',
          licensePlate: licensePlate || '',
          netCapacity: queue?.truck?.netCapacity || '',
          time: queue?.date || new Date(),
          toleranceTime: queue?.date || new Date(),
          isFreelancer: queue?.scheduling ? false : true,
          vehicleModelName: queue?.scheduling?.truck?.vehicleModel?.name || '',
          truck: queue?.truck || null,
          scheduling: queue?.scheduling || null,
          isInTolerance: isInTolerance,
          isActive: isActive,
          checkInActive: false,
          checkInId: null
        }

        array.push(newQueue)
      }
    }

    return array
  }

  function handleGetQueue(index: number) {
    const queue = filteredQueuesByDays[index]
    return queue
  }

  function handleVehicleIsInTolerance(queueDate: Date) {
    let isInTolerance = false

    const toleranceTime = differenceInMilliseconds(
      new Date(queueDate),
      new Date()
    )

    const enableToleranceStorage = JSON.parse(
      localStorageUtils.get('@enableTolerance')!
    )

    if (toleranceTime < 0 && enableToleranceStorage) {
      isInTolerance = true
    }

    return isInTolerance
  }

  function handleCreatePositionInQueue(
    queue: Queue,
    queueDay: number,
    isActive: boolean
  ) {
    const scheduleDay = new Date(queue.date).getDate()

    const lastPosition = localStorageUtils.get('@last-position')
    const lastPositionParse = Number(lastPosition)

    if (scheduleDay !== queueDay) {
      localStorage.setItem('@last-position', String(lastPosition))
    } else {
      if (lastPosition === '0') {
        localStorage.setItem('@last-position', String(lastPosition))
      } else if (!isActive) {
        localStorage.setItem('@last-position', String(lastPositionParse + 1))
      }
    }

    let queuePosition = String(lastPositionParse + 1) + '°'

    if (lastPositionParse === 0 && scheduleDay === queueDay && !isActive) {
      localStorage.setItem('@last-position', String(lastPositionParse + 1))
    }

    return queuePosition
  }

  function handleIsVehicleInQueueAttendance(
    queueId: number,
    queuesActive: Queue[]
  ) {
    const vehicleQueueAttendance = queuesActive.some(
      queueActive => queueActive.id === queueId
    )

    return vehicleQueueAttendance
  }

  function removeDaysOrDatesDuplicated(
    value: string,
    index: number,
    self: string[]
  ) {
    return self.indexOf(value) === index
  }

  function generateDaysAndDatesFromQueue(queues: Queue[]) {
    const days = queues
      .map(queue => String(new Date(queue.date).getDate()))
      .filter(removeDaysOrDatesDuplicated)

    const dates = queues
      .map(queue => {
        const dayMonth = new Date(queue.date).getDate()
        const month = new Date(queue.date).getMonth() + 1

        const date = `${dayMonth}/${month}`
        return date
      })
      .filter(removeDaysOrDatesDuplicated)

    return { days, dates }
  }

  function returnTime(attendanceTime: any) {
    let attendanceData = new Date(attendanceTime.time).toLocaleString('pt-BR', {
      hour: 'numeric',
      minute: '2-digit'
    })

    let countDown = attendanceData

    if (minutes > 0) {
      return countDown
    } else {
      return countDown
    }
  }

  function returnToleranceTime(attendanceTime: any) {
    let toleranceHours =
      Number(settingsCompany.tolerance.slice(0, 2)) * 60 * 60 * 1000
    let toleranceMinutes =
      Number(settingsCompany.tolerance.slice(3, 5)) * 60 * 1000

    let time =
      new Date(attendanceTime.time).getTime() +
      (toleranceHours + toleranceMinutes)

    new Date(attendanceTime.time).getTime()
    let timeleft = Math.round(time - new Date().getTime())

    let days: any = Math.floor(timeleft / (1000 * 60 * 60 * 24))
    let hours: any = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    let minutes: any = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
    let seconds: any = Math.floor((timeleft % (1000 * 60)) / 1000)

    if (seconds < 10 && seconds > 0) {
      seconds = '0' + seconds
    }
    if (minutes < 10 && minutes > 0) {
      minutes = '0' + minutes
    }
    if (hours < 10 && hours > 0) {
      hours = '0' + hours
    }
    if (days < 10 && days > 0) {
      days = '0' + days
    }

    let countDown = `${hours}:${minutes}:${seconds}`

    if (days > 0) {
      countDown = `${days}:${hours}:${minutes}:${seconds}`
    } else {
      countDown = `${hours}:${minutes}:${seconds}`
    }

    if (Number(minutes) < 0 || Number(hours) < 0 || Number(days) < 0) {
      return 'Esgotado'
    }

    if (minutes > 0) {
      return countDown
    } else {
      return countDown
    }
  }

  function returnLimitTime(attendanceTime: any) {
    let toleranceHours =
      Number(settingsCompany.tolerance.slice(0, 2)) * 60 * 60 * 1000
    let toleranceMinutes =
      Number(settingsCompany.tolerance.slice(3, 5)) * 60 * 1000

    let time =
      new Date(attendanceTime.time).getTime() +
      (toleranceHours + toleranceMinutes)

    let calculatedTime = new Date(time).toLocaleString('pt-br', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })

    return calculatedTime
  }

  function handleSelectScheduling(x: any) {
    if (isExpeditionViewer) {
      const queue = queues.find((item: Queue) => item.id === x.row.id)

      if (queue) {
        let newQueues: Array<any> = activedQueues
        newQueues.push(queue)

        setActivedQueues(newQueues)
        setTruckToAttend({ queue, checkinId: x.row.checkInId })
      }
    } else {
      if (queueVehiclesAttendance.find((item: Queue) => item.id === x.row.id)) {
      } else if ((attendance && !delayStatus) || shippingCompanyId) {
        const queue = queues.find((item: Queue) => item.id === x.row.id)
        if (queue) {
          let newQueues: Array<any> = activedQueues
          newQueues.push(queue)

          setActivedQueues(newQueues)
          setTruckToAttend({ queue, checkinId: x.row.checkInId })
        }
      } else {
        if (
          queueVehiclesAttendance.find((item: Queue) => item.id === x.row.id)
        ) {
        } else if (attendance && !delayStatus) {
          const queue = queues.find((item: Queue) => item.id === x.row.id)

          if (queue) {
            let newQueues: Array<any> = activedQueues
            newQueues.push(queue)

            setActivedQueues(newQueues)
            setTruckToAttend({ queue, checkinId: x.row.checkInId })
          }
        } else if (attendance && delayStatus) {
          toast.error('Por favor, cancelar o atraso!')
        } else toast.error('Por favor, iniciar o atendimento da empresa!')
      }
    }
  }

  function eachQueueTime(queue: any) {
    const timer = new Date().getTime() - new Date(queue?.expedition).getTime()

    return Math.floor(timer / (60 * 1000))
  }

  function handleClose() {
    setTruckToAttend(null)
  }

  function handleCancel() {
    setConfirm(1)
    setDelay(1)
  }

  function handleSuspend() {
    setModalToleranceType('end-attendance')
    handleConfirmSuspend()
  }

  async function handleTolerance() {
    if (!settingsCompany.enableTolerance) {
      setModalToleranceType('enable-tolerance')
      setModalSuspend(true)
    } else {
      setModalToleranceType('disable-tolerance')
      setModalSuspend(true)
    }
  }

  async function handleDelay() {
    if (!delayStatus) {
      setDelay(0)
    }
  }

  function handleConfirmSuspend() {
    if (!attendance) {
      api
        .post(`/attendances/save`, {
          user: { id: Number(userId) },
          company: { id: Number(companyId) }
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch(error => {
          toast.error(error.response.data.message)
        })
    } else {
      setModalSuspend(true)
    }
  }

  async function updateAttendances() {
    setIsClosingAttendance(true)

    try {
      await api
        .put(`/attendances/update`, {
          id: attendance
        })
        .then(() => {
          setIsClosingAttendance(true)
          window.location.reload()
        })
    } catch (error) {
      console.log(error)
    }
  }

  function handleChangeTabActive(tabOption: TabQueueOptions) {
    setTabSelected(tabOption)

    clearSearchesOfVehicles()
  }

  function sortByQueuePosition(array: QueueByDay[]) {
    return array.sort((a, b) => {
      const aPos = Number(a.position.replace('°', ''))
      const bPos = Number(b.position.replace('°', ''))

      return aPos - bPos
    })
  }

  async function fetchQueueVehiclesCourtyard() {
    const generalQueue = queuesByDays.flat()

    const _queueVehiclesCourtyard =
      await driverCheckInServices.getCheckInActives(companyId)

    const result = _queueVehiclesCourtyard.map(item => {
      return generalQueue
        .filter(queue => queue?.id === item?.queue?.id)
        .map(itemList => ({
          ...itemList,
          checkInActive: item.isPresent,
          checkInId: item.id
        }))
    })

    const flattenedResult = result.flat()
    const queueCheckins = sortByQueuePosition(flattenedResult)

    setQueueVehiclesCourtyard(queueCheckins)

    const updateGeneralQueue = queuesByDays.map(queueDay => {
      return queueDay.map(day => {
        const matchingItem = result.find(item =>
          item.some(queue => queue.id === day.id)
        )

        return matchingItem
          ? {
              ...day,
              checkInActive: matchingItem[0].checkInActive
            }
          : day
      })
    })

    setFirtsFourDaysQueues(
      generateQueuesForFourDays(days, updateGeneralQueue, 'company')
    )
  }

  async function fetchServicesStatus() {
    const data = await queueServices.getServicesStatus(companyId)
    setQueueStatus(data)
  }

  async function fetchSettingsCompany() {
    const _settingsCompany = await companyServices.getSettings(companyId)

    localStorage.setItem(
      '@enableTolerance',
      JSON.stringify(_settingsCompany.enableTolerance)
    )

    setSettingsCompany(_settingsCompany)
  }

  useEffect(() => {
    const totalVehiclesInAttendanceLength = queueVehiclesAttendance.length

    const whichTabShouldBeSelected =
      totalVehiclesInAttendanceLength > 0 ? 'attendance' : 'queue'

    setTabSelected(whichTabShouldBeSelected)
  }, [queueVehiclesAttendance])

  useEffect(() => {
    const isOnline = navigator.onLine

    if (!isOnline) {
      setIsUserConnected(false)
      sessionStorage.setItem('@checkedConnect', 'false')

      setIsOpenModalConnected(true)
    } else {
      setIsUserConnected(true)
      sessionStorage.setItem('@checkedConnect', 'true')

      setIsOpenModalConnected(true)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('@last-position', 0 + '')

    queryQueue()

    if (companyId) {
      api.get(`/attendances/today/company/${companyId}`).then(response => {
        setAttendance(
          response.data?.id && response.data?.status ? response.data.id : 0
        )
        localStorage.setItem(
          'attendance',
          response.data?.id && response.data?.status ? response.data.id : 0
        )
      })

      api.get(`queue/delayStatus/company/${companyId}`).then(response => {
        if (response.data) {
          setDelayStatus(true)
          setDelayTime(response.data.delayedTime)
          setDelayedTime(0)
          setDelayName(response.data?.delay?.reason)
          setIconName(response.data?.delay?.icon)

          let timer =
            new Date().getTime() - new Date(response.data.expedition).getTime()

          if (isNaN(timer)) {
            let date = new Date(response.data.created_at)

            timer = new Date().getTime() - date.getTime()

            let time = Math.round(Math.floor(timer / (60 * 1000)))
            setDelayedTime(time)
          }
        }
      })

      fetchServicesStatus()
      fetchSettingsCompany()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  useEffect(() => {
    if (queuesByDays.length > 0) {
      fetchQueueVehiclesCourtyard()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuesByDays])

  function handleUpdateTimestamp() {
    const dateOfToday = new Date().toLocaleDateString('pt-br')
    const time = new Date().toLocaleTimeString('pt-br')

    const reloadedTimestampFormatted = `${dateOfToday} às ${time}`

    sessionStorage.setItem('@reloadedTimestamp', reloadedTimestampFormatted)
    setTimestamp(reloadedTimestampFormatted)
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      handleUpdateTimestamp()

      window.location.reload()
    }, THREEMINUTES_IN_MILISECONDS_UPDATE_EXPED)

    return () => clearTimeout(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function createCheckInCall(data: CheckInCreateCall) {
    try {
      const checkinCall = await driverCheckInServices.createCall(data)
      const { driver, truck } = checkinCall.queue
      // Emite o evento do WebSocket
      webSocketClient.emit('createCheckInCall', checkinCall)
      toast.success(`Motorista chamado ${driver.name} ${truck.licensePlate}`)
    } catch (error) {
      console.log(error)
      toast.error('Erro ao fazer o chamado')
    }
  }

  return (
    <>
      {!shippingCompanyId && (
        <div className='home-expedition'>
          {loading && queuesIsEmpty ? (
            <ExpeditionSkeleton />
          ) : (
            <>
              <div className='home-expedition__header'>
                <AppToolBar pathToBack='/expedicao' isStatic={true} />

                {!isExpeditionViewer && !isConciergeViewer && (
                  <ExpeditionActions
                    attendance={attendance}
                    active={queueVehiclesAttendance}
                    toleranceIsEnabled={settingsCompany.enableTolerance}
                    handleTolerance={handleTolerance}
                    handleDelay={handleDelay}
                    handleSuspend={handleSuspend}
                  />
                )}
              </div>

              <div id='top' className='container'>
                <div className={disable ? 'disable' : 'sucess-schedule'}>
                  <img
                    src={_Iniciar}
                    width={'30%'}
                    style={{ maxWidth: '200px' }}
                    alt=''
                  />

                  <div>Atendimento</div>
                  <strong>Encerrado</strong>
                </div>

                <ExpeditionDelayReason
                  settingsCompanyDelay={settingsCompany.delay}
                  delayStatus={delayStatus}
                  expeditionDelayReasonIsActive={delay}
                  companyId={companyId}
                  onCloseDelayReasonForm={handleCancel}
                />

                {truckToAttend && (
                  <OrderDetails
                    limit={settingsCompany.programmedTotal}
                    attended={queueStatus.attended}
                    queue={truckToAttend.queue}
                    checkinId={truckToAttend.checkinId}
                    handleClose={handleClose}
                    tolerance={settingsCompany.enableTolerance}
                    isExpeditionViewer={isExpeditionViewer}
                    isConciergeViewer={isConciergeViewer}
                  />
                )}

                <div className='divisor'>
                  <div className='full'>
                    <div className='content'>
                      {!!shippingCompanyId === false && (
                        <>
                          <LastUpdateExpedition timestamp={timestamp} />

                          <ExpeditionQueueStatus
                            queueStatus={queueStatus}
                            isConciergeViewer={isConciergeViewer}
                            isExpeditionViewer={isExpeditionViewer}
                            checkinTotal={checkinTotal}
                          />
                        </>
                      )}

                      {delayStatus && (
                        <AttendanceWithDelayStatus
                          delayedTime={delayedTime}
                          minutes={minutes}
                          delayName={delayName}
                          iconName={iconName}
                          isExpeditionViewer={isExpeditionViewer}
                          setDelayTime={setDelayTime}
                          setDelayStatus={setDelayStatus}
                          delayTime={delayTime}
                          delayValue={delayValue}
                          companyId={companyId}
                        />
                      )}

                      <div className='tabs__expedition__container'>
                        <TabsHeading
                          tabSelected={tabSelected}
                          onChangeTabActive={handleChangeTabActive}
                          queueCount={queuesTotal}
                          courtyardCount={filteredVehicleCourtyard.length}
                          attendanceCount={attendanceTotal}
                        />

                        <div className='tab__content'>
                          {tabSelected === 'attendance' && (
                            <TabAttendanceContent
                              queueVehiclesAttendance={queueVehiclesAttendance}
                              isExpeditionViewer={isExpeditionViewer}
                              isConciergeViewer={isConciergeViewer}
                              shippingCompanyId={+shippingCompanyId}
                              handleQueueTime={eachQueueTime}
                              companyId={companyId}
                            />
                          )}

                          {tabSelected === 'queue' && (
                            <TabFilters
                              shippingCompanyId={+shippingCompanyId}
                              LeftComponent={
                                <SearchVehicleByLicensePlate
                                  licensePlate={searchVehicleQueue}
                                  onSearch={changeSearchVehicleQueue}
                                />
                              }
                            >
                              <TabQueueContent
                                tableKey='queue'
                                days={days}
                                dates={dates}
                                shippingCompanyId={+shippingCompanyId}
                                tolerance={settingsCompany.tolerance}
                                isExpeditionViewer={isExpeditionViewer}
                                isConciergeViewer={isConciergeViewer}
                                getQueueByDay={handleGetQueue}
                                getTime={returnTime}
                                getLimitTime={returnLimitTime}
                                getToleranceTime={returnToleranceTime}
                                handleSelectScheduling={handleSelectScheduling}
                                checkInEnabled={settingsCompany.enableCheckin}
                              />
                            </TabFilters>
                          )}

                          {tabSelected === 'courtyard' && (
                            <TabFilters
                              shippingCompanyId={+shippingCompanyId}
                              LeftComponent={
                                <SearchVehicleByLicensePlate
                                  licensePlate={searchVehicleCourtyard}
                                  onSearch={changeSearchVehicleCourtyard}
                                />
                              }
                            >
                              <TabCourtyardContent
                                tableKey='courtyard'
                                queueVehiclesCourtyard={
                                  filteredVehicleCourtyard
                                }
                                days={days}
                                dates={dates}
                                shippingCompanyId={+shippingCompanyId}
                                tolerance={settingsCompany.tolerance}
                                isExpeditionViewer={isExpeditionViewer}
                                isConciergeViewer={isConciergeViewer}
                                getQueueByDay={handleGetQueue}
                                getTime={returnTime}
                                getLimitTime={returnLimitTime}
                                getToleranceTime={returnToleranceTime}
                                handleSelectScheduling={handleSelectScheduling}
                                checkInEnabled={settingsCompany.enableCheckin}
                                createCheckInCall={createCheckInCall}
                              />
                            </TabFilters>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {tabSelected === 'queue' && (
                    <FilterTableByDaySidebar
                      dates={dates}
                      returnRows={handleGetQueue}
                    />
                  )}
                </div>

                <ToleranceWarningModal
                  modalSuspend={modalSuspend}
                  setModalSuspend={setModalSuspend}
                  modalToleranceType={modalToleranceType}
                  companyId={companyId}
                  updateAttendances={updateAttendances}
                  isClosingAttendance={isClosingAttendance}
                />

                <ConnectionCheckModal
                  open={isOpenModalConnected}
                  onClose={() => setIsOpenModalConnected(false)}
                  isUserConnected={isUserConnected}
                />

                <PageLoading
                  loading={loading}
                  onCloseLoading={handleCloseLoading}
                />
              </div>
            </>
          )}
        </div>
      )}

      {shippingCompanyId && (
        <div className='home-expedition'>
          <header className='shipping-company-header'>
            <div>
              <strong>Fila Virtual</strong>
            </div>
          </header>

          {queuesIsEmpty ? (
            <div className='no-queue-warn-container'>
              <div className='no-queue-warn'>
                <div className='image'>
                  <img
                    src={caminhaoIcon}
                    alt='imagem representando um caminhão'
                  />
                </div>

                <div className='text'>
                  <span>Atenção Transportadora,</span>

                  <p>
                    Neste momento, você não possui caminhões na fila virtual.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {dates.map((_, index) => {
                const rows = handleGetQueue(index)

                if (rows?.length > 0) {
                  return (
                    <ShippingCompanyTable
                      returnLimitTime={returnLimitTime}
                      date={dates[index]}
                      rows={rows}
                      shippingCompanyId={shippingCompanyId}
                      handleSelectScheduling={handleSelectScheduling}
                      returnToleranceTime={returnToleranceTime}
                      returnTime={returnTime}
                    />
                  )
                }

                return null
              })}
            </div>
          )}

          <PageLoading loading={loading} onCloseLoading={handleCloseLoading} />
        </div>
      )}
    </>
  )
}
