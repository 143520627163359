import { Box, Button, Modal, Typography } from '@material-ui/core'
import React, { ChangeEvent, FormEvent } from 'react'
import { AiOutlineWarning } from 'react-icons/ai'
import { CgArrowLongRight } from 'react-icons/cg'
import api from '../../../../../services/api'
import InputMask from 'react-input-mask'

import driverIcon from '../../../../../assets/images/icons/Motorista-theme.svg'
import caminhaoIcon from '../../../../../assets/images/icons/truck-theme.svg'
import { CompanyApi } from '../../../../../domain'
import { toast } from 'react-toastify'
import { isSuccessRequest } from '../../../../../utils'

type Props = {
  open: boolean
  setOpen: () => void
  driver: any
  company?: CompanyApi
  altCompany?: CompanyApi
  queueDate: string
  numberCode: string
  tokenModal: string
  handleTokenChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSuccess: (event: FormEvent) => void
}

export function TransferCompanyModal({
  open,
  setOpen,
  driver,
  company,
  altCompany,
  queueDate,
  numberCode,
  tokenModal,
  onSuccess,
  handleTokenChange
}: Props) {
  async function submit(event: FormEvent) {
    event.preventDefault()

    try {
      if (numberCode === tokenModal) {
        const response = await api.post('driver/save', {
          override: true,
          id: driver?.id,
          company: {
            id: company?.id
          }
        })

        if (isSuccessRequest(response.status)) {
          onSuccess(event)
        } else {
          throw new Error()
        }
      } else {
        toast.error('Token inválido')
      }
    } catch (error) {
      toast.error('Usuário ou senha inválido')
    }
  }

  const characterToken = numberCode.length

  const dateQueueFormat = new Date(queueDate)
    .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
    .split(' ')
  return (
    <Box className='modal expedition'>
      <Modal
        open={open}
        onClose={setOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <form onSubmit={submit}>
          <Box className=' suspend-warning body'>
            <Box className='container diver'>
              <Box>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  <div className='transfer advice'>
                    <div className='modal-header'>
                      <div className='bold-text'>
                        <AiOutlineWarning className='warn-icon' />
                        Atenção!
                      </div>
                    </div>
                    <div className='modal-text-title'>
                      Você possui cadastro na empresa{' '}
                      <span>{altCompany?.company}</span>, deseja transferir para
                      empresa <span>{company?.name}</span>?
                    </div>
                    <div className='visual-transfer'>
                      <div className='company-logo'>
                        <img
                          src={`${process.env.REACT_APP_API}/${altCompany?.logo}`}
                          alt=''
                        />
                      </div>

                      <div className='mid'>
                        <div className='icons'>
                          <div>
                            {' '}
                            <img alt='' src={driverIcon} />{' '}
                          </div>
                          <div>
                            {' '}
                            <img alt='' src={caminhaoIcon} />{' '}
                          </div>
                        </div>

                        <div className='arrow'>
                          <CgArrowLongRight />
                        </div>
                      </div>

                      <div className='company-logo'>
                        <img
                          src={`${process.env.REACT_APP_API}/${company?.logo}`}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
              </Box>
              <Box className='modal-content'>
                <div className='modal-text-paragraph'>
                  Ao efetuar a mudança,{' '}
                  <span>
                    motorista e veiculo são automaticamente desassociados da
                    fila da empresa atual e devem reiniciar o processo na nova
                    empresa.
                  </span>
                </div>

                {queueDate && (
                  <>
                    <div className='modal-row'></div>
                    <div className='modal-position'>
                      Sua posição na fila {altCompany?.company} com previsão
                      para{' '}
                      <span>
                        {dateQueueFormat[0]} ás {dateQueueFormat[1]}
                      </span>{' '}
                      será{' '}
                      <span className='red'>cancelada automaticamente</span>
                    </div>
                  </>
                )}
                <div className='modal-row'></div>
                <div className='modal-code'>
                  <h2>Está ciente disso e deseja continuar</h2>
                  <div>
                    <p>
                      Digite o código <span>{numberCode}</span> na caixa ao lado
                    </p>
                    <InputMask
                      max={5}
                      min={4}
                      mask={characterToken === 5 ? '99999' : '9999'}
                      id='inputToken'
                      required
                      value={tokenModal}
                      onChange={e => handleTokenChange(e)}
                      type='text'
                    />
                  </div>
                </div>
              </Box>
              <Box className='actions buttons transfer'>
                <Button
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  Não
                </Button>
                <Button type='submit'>Sim</Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Modal>
    </Box>
  )
}
