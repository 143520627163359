import React from 'react'
import { Box, Modal, Typography } from '@material-ui/core'

import nextcargoGif from '../../assets/images/carregando.svg'
import './styles.css'

interface DriverLoadingProps {
  loading: boolean
  onCloseLoading?: () => void
}

export function PageLoading({ loading, onCloseLoading }: DriverLoadingProps) {
  return (
    <Box className='modal load'>
      <Modal
        open={loading}
        onClose={onCloseLoading}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='modal-instance history-panel'
      >
        <Box className=' suspend-loading body'>
          <Box className='container'>
            <Box>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                <div className='loading'>
                  <img src={nextcargoGif} alt='load' />
                </div>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
