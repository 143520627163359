import { useEffect, useRef } from 'react'

type Options = {
  seconds?: number
}

/**
 * @param seconds time in milliseconds by default 180000ms (3min).
 */

export function useWindowReload(options?: Options) {
  const time = options?.seconds || 180000
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      window.location.reload()
    }, time)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])
}
