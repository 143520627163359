import api from '../../services/api'
import {
  CompanyAttendanceStatusApi,
  CompanyHostApi,
  SettingsCompanyApi,
  ShippingCompanysApi
} from './companyTypes'

async function getSettings(companyId: string) {
  const response = await api.get<SettingsCompanyApi>(
    `/settings/company/${companyId}`
  )
  return response.data
}

async function getCompanyByHost(host: string) {
  const response = await api.get<CompanyHostApi>('/company/host', {
    params: {
      host
    }
  })

  return response.data
}

async function getListShippingCompany(shippingCompanyId: string) {
  const params = {
    withQueue: 1
  }

  const response = await api.get<ShippingCompanysApi[]>(
    `/shipping-company/company/${shippingCompanyId}`,
    {
      params
    }
  )

  return response.data
}

async function getAttendanceStatus(companyId: string) {
  const response = await api.get<CompanyAttendanceStatusApi>(
    `/attendances/today/company/${companyId}`
  )
  return response.data
}

export const companyApi = {
  getSettings,
  getCompanyByHost,
  getListShippingCompany,
  getAttendanceStatus
}
