import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'

import {
  CheckInActiveApi,
  CompanyHostApi,
  companyServices,
  driverCheckInServices
} from '../../domain'
import { useWindowLocationHost } from '../../hooks/useWindowLocationHost'
import { useIPNetwork } from '../../hooks/useIPNetwork'
import {
  fetchDriversInAttendance,
  formatDriverName,
  getDriverCalledMostRecent
} from './utils'
import { DriverTVContent } from './components/DriverTVContent'
import { DriverTVLoading } from './components/DriverTVLoading'
import webSocketClient from '../../webSocketClient'

export type DriverTV = CheckInActiveApi
export type DriverCalled = CheckInActiveApi

export type TV = {
  driverTv: DriverTV
  inAttendance: boolean
}

export function DriversTV() {
  const [isWsListenerAdded, setIsWsListenerAdded] = useState(false)
  const [showTV, setShowTV] = useState(false)

  const [drivers, setDrivers] = useState<TV[]>([])
  const [lastDriverCalled, setLastDriverCalled] = useState<DriverCalled | null>(
    null
  )
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState<CompanyHostApi | null>(null)

  const driverIp = useIPNetwork()
  const { windowLocationHost } = useWindowLocationHost()

  async function handleTVData(companyCheckinIps: string, companyId: string) {
    const driverIpValid = companyCheckinIps
      .replace(' ', '')
      .split(',')
      .includes(driverIp)

    if (!driverIpValid) {
      return
    }

    const [inAttendance, driversCheckin] = await Promise.all([
      fetchDriversInAttendance(companyId),
      driverCheckInServices.getDriversCalled(companyId)
    ])

    let formattedDriverList = driversCheckin
      .filter(driver => driver.queue)
      .map(formatDriverName)

    const driverCalledMostRecent = getDriverCalledMostRecent(driversCheckin)

    if (driverCalledMostRecent) {
      setLastDriverCalled(driverCalledMostRecent)
    }

    setDrivers(
      formattedDriverList.map(item => {
        const truckId = item.queue?.truck?.id
        const isAttendanceDriver = inAttendance.includes(truckId)

        return {
          driverTv: item,
          inAttendance: isAttendanceDriver
        }
      })
    )
  }

  async function fetchDataPage() {
    if (!driverIp) return

    try {
      setLoading(true)

      const company = await companyServices.getCompanyByHost(windowLocationHost)
      setCompany(company)

      const companyId = company.id.toString()

      const [companySettings, companyAttendanceStatus] = await Promise.all([
        companyServices.getSettings(companyId),
        companyServices.getAttendanceStatus(companyId)
      ])

      if (companyAttendanceStatus) {
        setShowTV(companyAttendanceStatus.status)
      }

      await handleTVData(companySettings.checkinIp, companyId)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleWebSocketResponse = (message: {
      driverCalled: DriverCalled | null
    }) => {
      if (message.driverCalled && !isWsListenerAdded) {
        setIsWsListenerAdded(true)
      }
    }

    webSocketClient.on('checkInCallResponse', handleWebSocketResponse)

    return () => {
      webSocketClient.off('checkInCallResponse', handleWebSocketResponse)
      setIsWsListenerAdded(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchDataPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowLocationHost, driverIp, isWsListenerAdded])

  const attended = drivers.filter(driver => driver.inAttendance)
  const attendedTotal = attended.length

  const driversEmpty = drivers.length === 0

  return (
    <div className={styles['tv-container']}>
      {loading || driversEmpty || !showTV ? (
        <DriverTVLoading />
      ) : (
        <DriverTVContent
          lastDriverCalled={lastDriverCalled}
          attendeds={attendedTotal}
          tvList={drivers}
          company={company}
          refetchDriverList={fetchDataPage}
        />
      )}
    </div>
  )
}
